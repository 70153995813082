<template>
    <div>

        <div class="zozo-main-wrapper" style="min-height:400px">
            <div id="main" class="main-section">
                <div data-zozo-parallax="2" data-zozo-parallax-position="left top"
                    class="page-title-section page-titletype-default page-titleskin-default page-titlealign-default zozo-parallax"
                    v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                    <div class="page-title-wrapper clearfix">
                        <div class="container">
                            <div class="page-title-container">
                                <div class="page-title-captions">
                                    <h1 class="entry-title " style="font-family: 'Cairo' !important;">
                                        {{ $t("header.Blog") }}</h1>
                                </div>
                                <div class="page-title-breadcrumbs">
                                    <div id="breadcrumb" class=""
                                        v-bind:class="[{'text_en' : $i18n.locale=='ar' },{'text_Ar':$i18n.locale=='en'}]">
                                        <router-link to="/" style="font-family: 'Cairo' !important;">
                                            {{ $t("header.home") }}</router-link>
                                        <i class="fas fa-angle-left" style="margin-left:5px;margin-right:5px"></i>
                                        <span class="current"
                                            style="font-family: 'Cairo' !important;">{{ $t("header.Blog") }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="zozo_parallax-inner" data-center="top: 0%;" data-bottom-top="top: 0%;"
                        data-top-bottom="top: -100%;" style="height: 200%; bottom: 0px;"></div>
                </div>
      



                <div class="container">

   <div class="zozo-isotope-filters clearfix">
                    <ul class="zozo-smartmenu portfolio-tabs arrow-style-filter arrow-center text-center">
                        <li class="smartmenu-filter">
                            <ul class="smart-sub-menu portfolio-sub-filter"
                                v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                                <li><a  data-filter="*" @click="xxx(44411);getAll()"
                                        v-bind:class="[{'active' : active==44411 }]"><span>{{ $t("show_all") }}
                                        </span><span class="sub-arrow"></span></a></li>

                                <li v-for="item  in art_cats" :key="item"><a
                                        v-bind:class="[{'active' : active==item.id }]" data-filter="grid-cat-4"
                                        @click="xxx(item.id);getArt(item.id)"
                                        class=""><span>{{ item.name}}</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                    <v-layout row wrap v-if="loading" style="min-height: 1000px">

                        <v-flex xs4></v-flex>
                        <v-flex xs4 style="text-align: center;margin-top:25px;position: relative;top: 100px;">

                            <v-progress-circular v-if="loading" indeterminate color="#276050" :size="120">
                            </v-progress-circular>

                        </v-flex>



                        <v-flex xs4></v-flex>






                    </v-layout>
                    <div id="main-wrapper" class="zozo-isotope-wrapper no-gutter">
                        <div class="row"
                            v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                            <div>

                                <article id="post-145 " v-for="item in arts" :key="item"
                                    v-bind:class="[{'workk_ar' : $i18n.locale=='ar' },{'workk_en':$i18n.locale=='en'}]"
                                    class="isotope-post grid-posts post-iso-w4 post-iso-h4 post-145 post type-post status-publish format-standard has-post-thumbnail hentry category-news tag-featured"
                                    style=" width: 365px; display: inline-block !important">
                                    <div class="post-inner-wrapper post-inside-wrapper margin-top-20"
                                        style="opacity: 1;">
                                        <div class="post-featured-image only-image">
                                            <div class="entry-thumbnail-wrapper">
                                                <div class="entry-thumbnail">
                                                    <router-link :to="{ name: 'art', params: { id:item.id }}"
                                                        style="font-family: 'Cairo' !important;height:235px"
                                                        class="post-img" data-title="Adopt Child" :title="item.title">
                                                           <v-img v-if="item.image==null" width="570" height="370" 
                                                           src="http://abnakr.org/logo.png"
                                                                
                                                            
                                                            class="attachment-charity-blog-medium size-charity-blog-medium wp-post-image"
                                                            alt="" loading="lazy" />


                                                        <v-img v-else 
                                                            :src="Url+'/art_img/thumb_new/'+item.image.image_name"
                                                                 :lazy-src="Url+'/art_img/small_thumb/'+item.image.image_name"
                                                            
                                                            class="attachment-charity-blog-medium size-charity-blog-medium wp-post-image"
                                                            alt="" loading="lazy" />
                                                            
                                                            
                                                            </router-link>
                                                    <div class="post-date-wrap">
                                                        <h6 class="post-date">
                                                            {{item.created_at|formatMon}}


                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="posts-content-container"  style="    height: 257px;">
                                            <div class="entry-header">
                                                <h2 class="entry-title" style="font-family: 'Cairo' !important;">
                                                    {{item.articles_cats.name}}
                                                </h2>
                                            </div>

                                            <div class="entry-summary clearfix" style="height: 71px !important">


                                                <p>{{item.title|subStrAds}}</p>
                                            </div>
                                            <div class="entry-meta-wrapper">
                                                <div class="entry-meta">
                                                    <div class="post-author">
                                                        <i class="fas fa-calendar-alt"></i>
                                                        <h6 style="padding-right: 9px;color: #524c4c;"
                                                            class="post-author-name">
                                                            {{item.created_at|formatMonn}}
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="read-more">
                                                    <router-link :to="{ name: 'art', params: { id:item.id }}"
                                                        style="font-family: 'Cairo' !important;">

                                                        {{ $t("more") }}

                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </article>

                            </div>
                        </div>
                    </div>


                      <v-layout row wrap style="
                         float: right;
    direction: rtl;
                     
                     ">

                        <v-flex style="float:right" md4 sm4 xs12 class="hidden-lg-and-up">
                            <br>

                            <v-pagination class="hidden-lg-and-up" :total-visible="6" v-if="!loading" v-model="page"
                                color="#276050" circle="" :length="pageCount">


                            </v-pagination>



                        </v-flex>
                    </v-layout>
                </div>
            </div>

        </div>
    </div>
</template>




<style>
    .workk_ar:not(:first-child) {
        margin-right: 17px !important
    }

    .workk_en:not(:first-child) {
        margin-left: 17px !important
    }
</style>

<script>
    import Axios from "axios";
    import {
        EventBus
    } from '../../components/core/event-bus.js';

    export default {
        data: () => ({
            arts: [],
            art_cats: [],
             active: 44411,
            loading: true,


             page: 1,
                pageCount: 0,
                current_page: 1,
                last_page: 0,


        }),

         watch: {
            selected: 'search by sub_cat_id',
        },
        computed: {

            selected: function () {
                return this.getMoreitems();
            }
        },

         filters: {

            subStrAds: function (string) {

                if (string != null) {
                    return string.substring(0, 110) + ' ... ';
                }

            },

            subStrSlider: function (string) {

                if (string != null) {
                    return string.substring(0, 150) + ' ... ';
                }

            },


            subDetails: function (string) {

                if (string != null) {
                    return string.substring(0, 300) + ' ... ';
                }

            },



            subStrArtDetailss_lastt: function (string) {

                if (string != null) {
                    return string.substring(0, 140) + '... ألمزيد';
                }

            }





        },

        methods: {

            getMoreitems() {

                if (this.current_page <= this.last_page) {
                    this.current_page = this.page;



                    this.getArt(this.$route.params.id)
                }



            },

             xxx(item) {

                this.active = item

            },
   getArtCat() {
                this.loading = true;
                Axios.get("articlesCats/GetByLang/65", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                              lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.AdminInfo.token
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.art_cats = res.data.data;
                    
                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },
            getAll() {
                this.loading = true;
               
                this.$router.push({path:'/blog'});
                Axios.get("/articles/GetByLang/544", {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.arts = res.data.data;
                       
                        

                    })
                    .catch(() => {
                        this.loading = false;
                    });


            },
           
             scrollToTop() {
                window.scrollTo(0, 0);
            },
            getArt(id) {
                this.loading = true;
                this.xxx(id);
           
              
            
                Axios.get("/articles/GetByCatId/40/"+id+"?page=" + this.current_page, {
                        headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            lang: this.$i18n.locale,
                            Authorization: "Bearer " + this.$store.state.idToken
                        }
                    })
                    .then(res => {
                        this.loading = false;
                        this.arts = res.data.data;
                          this.last_page = res.data.meta.last_page
                        this.pageCount = res.data.meta.last_page
                        this.scrollToTop()



                    })
                    .catch(() => {
                        this.loading = false;
                    });
            },



            changeLang() {


            },
        },


        created() {
            EventBus.$on("changeLangs", (from) => {
                from
                this.getArt(this.$route.params.id)
                this.getArtCat()



            });
            this.getArt(this.$route.params.id)
            this.getArtCat()
        },

    }
</script>